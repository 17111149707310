<template>
  <div>
    <drag-three :gData="gData" @editTree="editTree" :draggable="false" />
  </div>
</template>

<script>
import DragThree from "./component/DragThree.vue";
import { ClassificationAPi } from "@/api/Classification.js";
import { tips } from "../../utils/tips";
import { loading } from "@/utils/tips.js";
export default {
  components: {
    DragThree,
  },
  data() {
    return {
      gData: [],
    };
  },
  methods: {
    //获取分类数据
    async getCateDate() {
      let res = await ClassificationAPi.getCateGoryData();
      if (res.success) {
        this.addParams(res.data);
        this.gData = res.data;
      }
    },
    addParams(arr) {
      arr.forEach((v) => {
        v.scopedSlots = { title: "custom" };
        v.key = v.id;
        v.name = v.label;
        // v.title = v.label;
        if (v.children && v.children.length) {
          this.addParams(v.children);
        }
      });
    },
    async editTree(data) {
      if (data.type === "add") {
        let target = {
          categoryName: data.val,
          parentId: data.parent,
          state: true,
        };
        loading.show();
        try {
          let msg = data.cate === 'xia' ? '添加下级':"添加同级"
          let res = await ClassificationAPi.AddSibling(target);
          tips(res, msg);
          this.getCateDate();
          loading.close();
        } catch (e) {
          loading.close();
        }
      } else if (data.type === "state") {
        loading.show();
        try {
          let res = await ClassificationAPi.changeState(data);
          tips(res, `设置${data.state ? "启用" : "禁用"}状态`);
          this.getCateDate();
          loading.close();
        } catch (e) {
          loading.close();
        }
      }
    },
  },
  created() {
    this.getCateDate();
  },
};
</script>

<style scoped lang='scss'>
/deep/ .tree {
  overflow-y: auto;
}
</style>