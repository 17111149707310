<template>
  <div class="bottom-container">
    <div class="cate-title">
      <span>选品分类</span>
      <a-tooltip placement="top">
        <template slot="title">
          <span> 添加下级分类 </span>
        </template>
        <a-icon
          @click="(event) => onAddChild(event, 0)"
          style="margin-left: 10px; font-size: 14px"
          type="plus-circle"
        />
      </a-tooltip>
    </div>
    <div class="tree">
      <!-- <a-input-search
        style="margin-bottom: 8px"
        :placeholder="`搜索${name}`"
        @change="onChange"
      /> -->
      <div class="title">
        <span>{{ CompanyNmae }}</span>
        <span class="toolbar margr">
          <a-tooltip placement="top">
            <template slot="title">
              <span>添加下级部门</span>
            </template>
            <a-icon
              class="icons"
              @click="(event) => onAddChild(event, 0)"
              type="plus-circle-o"
            ></a-icon>
          </a-tooltip>
        </span>
      </div>
      <a-tree
        v-if="gData.length > 0"
        class="draggable-tree"
        :show-line="showline"
        default-expand-all
        dropdownClassName="dropdown"
        :default-selected-keys="defaultKey"
        v-bind="$attrs"
        :tree-data="gData"
        :expanded-keys="OpenexpandedKeys"
        @dragenter="onDragEnter"
        @drop="onDrop"
        @expand="onExpand"
        @select="selectNode"
        @dragstart="isDrop = true"
        show-icon
      >
        <template slot="custom" slot-scope="item">
          <div class="node">
            <span v-if="!item.edit" :title="item.name" v-html="handlerSearch(item.name)"> </span>
            <a-input
              v-if="!isDrop && item.edit"
              ref="input"
              class="editbox"
              v-model="editNode"
              :placeholder="`请输入分类名称`"
              @blur="handleOk(item)"
            >
            </a-input>
            <span class="toolbar" v-show="!item.edit && !isDrop">
              <a-tooltip placement="top" v-show="item.slots.icon === 'green'">
                <template slot="title">
                  <span>添加同级</span>
                </template>
                <a-icon
                  class="icons"
                  @click="(event) => onAddBro(event, item)"
                  type="plus-square"
                ></a-icon>
              </a-tooltip>
              <a-tooltip placement="top" v-show="item.slots.icon === 'green'">
                <template slot="title">
                  <span>添加下级</span>
                </template>
                <a-icon
                  class="icons"
                  @click="(event) => onAddChild(event, item)"
                  type="plus-circle-o"
                ></a-icon>
              </a-tooltip>
              <!-- <a-tooltip placement="top">
                <template slot="title">
                  <span>修改</span>
                </template>
                <a-icon
                  class="icons"
                  @click="(event) => onEdit(event, item)"
                  type="edit"
                ></a-icon>
              </a-tooltip> -->
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{
                    item.slots.icon === "green" ? "禁用" : "启用"
                  }}</span>
                </template>
                <a-icon
                  class="icons"
                  @click="(event) => onRemove(event, item)"
                  :type="item.slots.icon === 'green' ? 'eye-invisible' : 'eye'"
                ></a-icon>
              </a-tooltip>
            </span>
          </div>
        </template>
        <!-- <template slot="label" slot-scope="title">
          <div>{{ title }} +1</div>
        </template> -->
        <span slot="green" class="green_radio"></span>
        <!-- <a-icon :style="{'color':'green'}"  type="star"  slot="meh"/> -->
        <span slot="Nstatus" class="Nstatus_radio"></span>
        <!-- <span slot="existence" class="existence_radio"></span>  -->
        <!-- <a-icon :style="{'color':'#333'}"  type="star"  slot="Nstatus"/> -->
      </a-tree>
      <template v-else>
        <a-empty :image="simpleImage" style="margin-top: 200px" />
      </template>
    </div>
  </div>
</template>
<script>
import { Empty } from "ant-design-vue";
export default {
  naem: "",
  data() {
    return {
      // gData,
      simpleImage: "",
      expandedKeys: [],
      OpenexpandedKeys: [],
      visible: false,
      confirmLoading: false,
      node: "",
      editNode: "",
      curNode: {},
      dataList: [],
      searchValue: "",
      editBox: {},
      defaultKey: [],
      showline: false,
      watchFirst: true,
      autoExpandParent: true,
      // 是否储存老数据
      oldValue: "",
      // blur和enter事件只执行一次
      blurOrEdter: "blur",
      isDrop: false,
    };
  },
  watch: {
    editBox: {
      handler(newVal) {
        if (newVal.type === "edit") {
          // 判断是否为第一次老数据
          this.oldValue = this.editNode;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    gData: {
      type: Array,
      default: () => [
        {
          name: "0-0",
          key: "0-0",
          edit: false,
          scopedSlots: {
            title: "custom",
          },
          children: [
            {
              name: "0-0-0",
              key: "0-0-0",
              scopedSlots: {
                title: "custom",
              },
              children: [
                {
                  name: "0-0-0-0",
                  key: "0-0-0-0",
                  scopedSlots: { title: "custom" },
                },
                {
                  name: "0-0-0-1",
                  key: "0-0-0-1",
                  scopedSlots: { title: "custom" },
                },
                {
                  name: "0-0-0-2",
                  key: "0-0-0-2",
                  scopedSlots: { title: "custom" },
                },
              ],
            },
            {
              name: "0-0-1",
              key: "0-0-1",
              scopedSlots: { title: "custom" },
              children: [
                {
                  name: "0-0-1-0",
                  key: "0-0-1-0",
                  scopedSlots: { title: "custom" },
                },
                {
                  name: "0-0-1-1",
                  key: "0-0-1-1",
                  scopedSlots: { title: "custom" },
                },
                {
                  name: "0-0-1-2",
                  key: "0-0-1-2",
                  scopedSlots: { title: "custom" },
                },
              ],
            },
            {
              name: "0-0-2",
              key: "0-0-2",
              scopedSlots: { title: "custom" },
            },
          ],
        },
        {
          name: "0-1",
          key: "0-1",
          scopedSlots: { title: "custom" },
          children: [
            {
              name: "0-1-0-0",
              key: "0-1-0-0",
              scopedSlots: { title: "custom" },
            },
            {
              name: "0-1-0-1",
              key: "0-1-0-1",
              scopedSlots: { title: "custom" },
            },
            {
              name: "0-1-0-2",
              key: "0-1-0-2",
              scopedSlots: { title: "custom" },
            },
          ],
        },
        {
          name: "0-2",
          key: "0-2",
          scopedSlots: { title: "custom" },
        },
      ],
    },
    name: {
      type: String,
    },
    CompanyNmae: {
      type: String,
    },
  },
  methods: {
    onDragEnter(info) {
      this.expandedKeys = info.expandedKeys;
    },
    onDrop(info) {
      this.isDrop = false;
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split("-");
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);
      //判断到哪个部门之上,
      let nodeBottom = -1;
      let nParentId = 0;
      let into = false;
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };
      const data = [...this.gData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.push(dragObj);
          nParentId = item.id;
          into = true;
          nodeBottom = item.id;
        });
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj);
          nodeBottom = item.id;
          into = false;
          nParentId = item.id;
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
          nodeBottom = ar[i + 1].id;
          into = false;
          nParentId =
            typeof this.getParentKey(ar[i + 1].id, this.gData) === "undefined"
              ? 0
              : this.getParentKey(ar[i + 1].id, this.gData);
        } else {
          ar.splice(i + 1, 0, dragObj);
          //判断是否为最后
          if (i + 2 >= ar.length) {
            nodeBottom = -1;
          } else {
            nodeBottom = ar[i + 2].id;
          }
          into = false;
          nParentId =
            typeof this.getParentKey(ar[i + 1].id, this.gData) === "undefined"
              ? 0
              : this.getParentKey(ar[i + 1].id, this.gData);
        }
      }
      let type = {
        type: "change",
        id: info.dragNodesKeys[info.dragNodesKeys.length - 1],
        nextDeptId: nodeBottom,
        data: data,
        isInto: into,
        nextParentId: nParentId,
      };
      this.$emit("editTree", type);
      // this.gData = data;
    },

    // 递归查找
    getTreeDataByKey(childs = [], findKey) {
      let finditem = null;
      for (let i = 0, len = childs.length; i < len; i++) {
        let item = childs[i];
        if (item.key !== findKey && item.children && item.children.length > 0) {
          finditem = this.getTreeDataByKey(item.children, findKey);
        }
        if (item.key == findKey) {
          finditem = item;
        }
        if (finditem != null) {
          break;
        }
      }
      return finditem;
    },

    //获取父组件
    getTreeParentChilds(childs = [], findKey) {
      let parentChilds = [];
      for (let i = 0, len = childs.length; i < len; i++) {
        let item = childs[i];
        if (item.key !== findKey && item.children && item.children.length > 0) {
          parentChilds = this.getTreeParentChilds(item.children, findKey);
        }
        if (item.key == findKey) {
          parentChilds = childs;
        }
        if (parentChilds.length > 0) {
          break;
        }
      }
      return parentChilds;
    },

    //获取子元素完整路径
    findPathbyId(tree, key, path) {
      if (typeof path == "undefined") {
        path = [];
      }
      for (var i = 0; i < tree.length; i++) {
        var tempPath = [...path];
        tempPath.push(tree[i].key);
        if (tree[i].key == key) {
          return tempPath;
        }
        if (tree[i].children) {
          let reuslt = this.findPathbyId(tree[i].children, key, tempPath);
          if (reuslt) {
            return reuslt;
          }
        }
      }
    },
    //添加同级
    onAddBro(event, item) {
      this.findEmpty(this.gData);
      event.stopPropagation();
      let parentChilds = this.getTreeParentChilds(this.gData, item.key);
      let ParentKey = this.getParentKey(item.key, this.gData);
      ////传递给input事件框
      if (typeof ParentKey === "undefined") {
        this.editBox.parent = null;
        this.OpenexpandedKeys = [];
      } else {
        this.editBox.parent = ParentKey;
        //打开父级别
        let parentNode = this.findPathbyId(this.gData, ParentKey);
        this.OpenexpandedKeys = parentNode;
      }
      // this.editBox.type = "add";
      this.$set(this.editBox, "type", "add");
      this.$set(this.editBox, "cate", "tong");
      parentChilds.push({
        key: new Date().getTime(),
        name: "",
        edit: true,
        scopedSlots: { title: "custom" },
        slots: { icon: "green" },
      });
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    // 添加按钮
    onAddChild(event, item) {
      event.stopPropagation();
      if (item === 0) {
        let lits = this.gData;
        lits.push({
          key: new Date().getTime(),
          name: "",
          edit: true,
          scopedSlots: { title: "custom" },
          slots: { icon: "green" },
        });
        this.$nextTick(() => {
          this.$refs.input.focus();
        });

        //传递给input事件框
        this.editBox.parent = null;
        // this.editBox.type = "add";
        this.$set(this.editBox, "type", "add");
        this.$set(this.editBox, "cate", "xia");
        //事件刷行
        this.$forceUpdate();
      } else {
        let selectItem = this.getTreeDataByKey(this.gData, item.key);
        if (!selectItem.children) {
          this.$set(selectItem, "children", []);
        }
        this.findEmpty(this.gData);
        selectItem.children.push({
          key: new Date().getTime(),
          name: "",
          edit: true,
          scopedSlots: { title: "custom" },
          slots: { icon: "green" },
        });
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
        //打开父级别
        let parentNode = this.findPathbyId(this.gData, selectItem.key);
        this.OpenexpandedKeys = parentNode;
        //传递给input事件框
        this.editBox.parent = selectItem.id;
        // this.editBox.type = "add";
        this.$set(this.editBox, "type", "add");
        this.$set(this.editBox, "cate", "xia");

        //事件刷行
        // this.$forceUpdate();
      }
    },

    onEdit(event, item) {
      this.findEmpty(this.gData);
      let selectItem = this.getTreeDataByKey(this.gData, item.key);
      let parentNode = this.findPathbyId(this.gData, selectItem.key);
      this.editNode = item.name;
      //传递给input事件框
      this.editBox.parent = Number(parentNode[0]);
      this.editBox.id = item.id;
      // this.editBox.type = "edit";
      this.$set(this.editBox, "type", "edit");

      //静态
      this.$set(selectItem, "edit", true);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
      event.stopPropagation();
    },

    onRemove(envent, item) {
      let type = {
        type: "state",
        parentId: item.parentId,
        categoryName: item.name,
        categoryId: item.id,
        state: item.slots.icon === "green" ? false : true,
      };
      this.$emit("editTree", type);
      // let _this = this;
      // this.$confirm({
      //   title: `是否删除该${this.name}?`,
      //   cancelText: "取消",
      //   okText: "确定",
      //   content: "",
      //   onOk() {
      //     let type = { type: "delte", id: item.id };
      //     _this.$emit("editTree", type);
      //     let parentChilds = _this.getTreeParentChilds(_this.gData, item.key);
      //     let delIndex = parentChilds.findIndex(
      //       (items) => items.key === item.key
      //     );
      //     parentChilds.splice(delIndex, 1);
      //     event.stopPropagation();
      //   },
      //   onCancel() {},
      // });
    },
    handleOk(item) {
      if (this.editNode.trim() === "") {
        let parentChilds = this.getTreeParentChilds(this.gData, item.key);
        let delIndex = parentChilds.findIndex(
          (items) => items.key === item.key
        );
        if (this.editBox.type === "edit") {
          parentChilds[delIndex].name = this.oldValue;
          this.oldValue = "";
          this.$message.error("分类名称不能为空");
          item.dataRef.edit = false;
        } else {
          parentChilds.splice(delIndex, 1);
          this.$message.error("分类名称不能为空");
          item.dataRef.edit = false;
        }
        this.editNode = "";
        this.editBox = {};
        // parentChilds.splice(delIndex, 1);
      } else {
        // 提交到index线上
        this.editBox.val = this.editNode;
        this.$emit("editTree", this.editBox);
        // this.handleThree(this.editBox)
        //本地虚拟增加分支静态增加
        item.dataRef.name = this.editNode;
        item.dataRef.edit = false;
        this.editNode = "";
        this.editBox = {};
      }
    },
    // handelEdter(item, type){
    //   this.$refs.input.blur(item,type)
    // },
    onExpand(expandedKeys) {
      this.OpenexpandedKeys = expandedKeys;
      //   this.autoExpandParent = false;
    },

    onChange(e) {
      let value = e.target.value.trim();
      let OpenexpandedKeys = this.dataList
        .map((item) => {
          if (item.name.indexOf(value) > -1) {
            return this.getParentKey(item.key, this.gData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        OpenexpandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
      this.ExpandAll();
    },

    //遍历所有数据放入datalist
    generateList(data, type = false) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        this.dataList.push({ key: node.key, name: node.name });
        if (type) {
          this.OpenexpandedKeys.push(key);
        }
        if (node.children.length > 0) {
          this.generateList(node.children);
        }
      }
    },

    //获取父级结构
    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },

    //对数组中正在编辑的进行校验
    findEmpty(data) {
      for (let i = 0; i < data.length; i++) {
        let node = data[i];
        let _this = this;
        if (node.edit) {
          if (node.name.trim() === "") {
            //为编辑无数据
            let parentChilds = _this.getTreeParentChilds(_this.gData, node.key);
            let delIndex = parentChilds.findIndex(
              (items) => items.key === node.key
            );
            parentChilds.splice(delIndex, 1);
          } else {
            let selectItem = this.getTreeDataByKey(this.gData, node.key);
            this.$set(selectItem, "edit", false);
            this.editNode = "";
          }
        }
        if (node.children) {
          this.findEmpty(node.children);
        }
      }
    },

    //点击Node
    selectNode(key, item) {
      this.$emit("getNodeId", item.node.eventKey);
    },
    // 全部折叠
    Allfold() {
      this.OpenexpandedKeys = [];
    },
    // 全部展开
    ExpandAll() {
      let parent = [];
      this.OpenexpandedKeys = this.getAllparent(this.gData, parent);
    },
    // 获取所有父节点
    getAllparent(Gdata, parent = []) {
      for (var i = 0; i < Gdata.length; i++) {
        parent.push(Gdata[i].key);
        if (Gdata[i].children.length > 0) {
          this.getAllparent(Gdata[i].children, parent);
        } else {
          continue;
        }
      }
      return parent;
    },
    addTong() {
      this.$refs.addTong.click();
    },
    // handleThree({type}){
    //   if(type === 'add'){
    //     console.log('新增');
    //   }
    // }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  mounted() {},
  created() {},
  computed: {
    handlerSearch() {
      return function (value) {
        let target = `<span style="color: #f50">${this.searchValue}</span>`;
        let reg = new RegExp(this.searchValue, "g");
        // console.log(value.replace(reg, target));
        return value.replace(reg, target);
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.tree {
  min-width: 20rem;
  //   width: 30rem;
  padding: 10px;
  padding-left: 20px;
  border-radius: 5px;
  border: 0.1rem solid #eaeaea;
  height: 697px;
}
.title {
  font-size: 16px;
  font-weight: bold;
  .margr {
    display: none;
  }
  &:hover {
    .margr {
      display: inline-block;
    }
  }
  .icons {
    margin-left: 0.8rem;
  }
}
.margr {
  margin-left: 1rem;
}
.editbox {
  height: 30px;
  line-height: 30px;
  /deep/ .ant-input {
    height: 25px;
    line-height: 25px;
  }
}
.ant-tree /deep/li span {
  height: 30px;
  line-height: 30px;
}
.node {
  .tree {
    font-size: 1.8rem;
  }
  .icons {
    margin-left: 1rem;
    display: none;
  }
  &:hover {
    .icons {
      display: inline-block;
    }
  }
}
.ant-tree li .ant-tree-node-content-wrapper .node span:nth-child(1) {
  float: left;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-bottom: 10px;
}
/deep/.ant-tree-iconEle {
  position: absolute;
  left: -39px;
  top: 2px;
}
.green_radio {
  width: 10px;
  height: 10px !important;
  background: #1abe6b;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}
.Nstatus_radio {
  width: 10px;
  height: 10px !important;
  background: #ccc;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}
.existence_radio {
  width: 5px;
  height: 5px !important;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 3px;
  border: 5px solid #ccc;
  position: relative;
  &::after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #1abe6b;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}
/deep/.ant-tree li .ant-tree-node-content-wrapper {
  color: unset;
  background-color: unset;
  position: relative;
  &:hover {
    color: #1890ff;
  }
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #1890ff;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper .toolbar {
  color: #1890ff;
}
.cate-title {
  margin-bottom: 20px;
}
</style>
