import axios from "@/config/http";
export const ClassificationAPi = {
    // 添加同级
    AddSibling(data){
        return axios.post('/goodsCategory',data)
    },
    // 修改分类状态
    changeState(data){
        return axios.put('/goodsCategory',data)
    },
    // 获取所有商品分类goodsCategory/tree
    getCateGoryData(params){
        return axios.get('goodsCategory/tree',{params})
    }
}